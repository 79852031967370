<div class="details_section">

  <div class="details_header_section">
    <h1>{{localize.language.company}}</h1>
  </div>

  <div class="golden_standard_section">

    <div class="golden_standard_block">
      <div class="golden_standard_info">
        <span class="common_medium_span">{{localize.language.goldenStandard}}</span>
        <h2>{{localize.language.goldenStandardText}}</h2>
      </div>
      <img src="../../../../../assets/images/shared/company/companyMobile.svg">
    </div>
  </div>

  <div class="company_heads_section">
    <div class="company_heads_outer_block">
      <div class="company_heads_info">
        <img width="40px" src="assets/images/shared/ui-kit/quoteMark.svg">
        <h2>{{localize.language.companyHeadText}}</h2>
      </div>

      <img width="100%" src="assets/images/shared/company/companyHead.jpg"/>

      <div class="company_heads_footer">
        <span class="common_big_span">{{localize.language.companyHeadName}}</span>
        <span class="common_big_span_grey">{{localize.language.companyHeadOccupation}}</span>
      </div>
    </div>
  </div>

  <div class="our_history_section">
    <div class="our_history_header_section">
      <span class="common_medium_span">{{localize.language.ourHistory}}</span>
    </div>

    <div class="our_history_body_section">
      <!--      <div class="our_history_left">-->
      <!--        <h1 class="our_history_left_2014">2014 {{localize.language.year}}</h1>-->
      <!--        <h1 class="our_history_left_2017">2017 {{localize.language.year}}</h1>-->
      <!--        <h1 class="our_history_left_2019">2019 {{localize.language.year}}</h1>-->
      <!--        <h1 class="our_history_left_2020">2020 {{localize.language.year}}</h1>-->
      <!--        <h1 class="our_history_left_2021">2021 {{localize.language.year}}</h1>-->
      <!--        <h1>2022 {{localize.language.year}}</h1>-->
      <!--      </div>-->

      <!--      <div class="our_history_middle">-->
      <!--        <div class="circle_history"></div>-->
      <!--        <div class="line_history line_1"></div>-->

      <!--        <div class="circle_history"></div>-->
      <!--        <div class="line_history line_2"></div>-->

      <!--        <div class="circle_history"></div>-->
      <!--        <div class="line_history line_3"></div>-->

      <!--        <div class="circle_history"></div>-->
      <!--        <div class="line_history line_4"></div>-->

      <!--        <div class="circle_history"></div>-->
      <!--        <div class="line_history line_5"></div>-->

      <!--        <div class="circle_history_last"></div>-->
      <!--      </div>-->

      <div class="our_history_right">
        <div class="our_history_right_inner">
          <div class="circle_section">
            <div class="circle_history"></div>
            <div class="line_history"></div>
          </div>
          <div class="our_history_info">
            <h1>2014 {{localize.language.year}}</h1>
            <span class="common_big_span_grey">{{localize.language.ourHistoryText1}}</span>
          </div>
        </div>

        <div class="our_history_right_inner">
          <div class="circle_section">
            <div class="circle_history"></div>
            <div class="line_history"></div>
          </div>
          <div class="our_history_info">
            <h1>2017 {{localize.language.year}}</h1>
            <img width="100%" src="assets/images/shared/company/companyHistory2017.svg">
            <span class="common_big_span_grey">{{localize.language.ourHistoryText2}}</span>
          </div>
        </div>

        <div class="our_history_right_inner">
          <div class="circle_section">
            <div class="circle_history"></div>
            <div class="line_history"></div>
          </div>
          <div class="our_history_info">
            <h1>2019 {{localize.language.year}}</h1>
            <span class="common_big_span_grey">{{localize.language.ourHistoryText3}}</span>
            <img width="100%" src="assets/images/shared/company/companyHistory2019.svg">
          </div>

        </div>

        <div class="our_history_right_inner">
          <div class="circle_section">
            <div class="circle_history"></div>
            <div class="line_history"></div>
          </div>
          <div class="our_history_info">
            <h1>2020 {{localize.language.year}}</h1>
            <img width="100%" src="assets/images/shared/company/companyHistory2020.svg">
            <span class="common_big_span_grey">{{localize.language.ourHistoryText4}}</span>
          </div>
        </div>

        <div class="our_history_right_inner">
          <div class="circle_section">
            <div class="circle_history"></div>
            <div class="line_history"></div>
          </div>
          <div class="our_history_info">
            <h1>2021 {{localize.language.year}}</h1>
            <span class="common_big_span_grey">{{localize.language.ourHistoryText5}}</span>
          </div>
        </div>

        <div class="our_history_right_inner">
          <div class="circle_section">
            <div class="circle_history_last"></div>
          </div>
          <div class="our_history_info">
            <h1>2022 {{localize.language.year}}</h1>
            <span class="common_big_span_grey">{{localize.language.outHistoryText6}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer-links></app-footer-links>
</div>
