<div class="not_found_section">

  <div class="not_found_block">
    <div class="not_found_info">
      <h1>{{localize.language.notFound}}</h1>
      <span class="common_big_span_grey">{{localize.language.notFoundText}}</span>
    </div>

    <button class="small_button transparent_button" routerLink="/general">{{localize.language.backToGeneral}}</button>
  </div>

  <app-footer-links></app-footer-links>
</div>
