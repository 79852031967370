<div class="login_register_section">
  <h1>{{localize.language.registerAccount}}</h1>
  <span class="common_big_span_grey">{{localize.language.becomeAClient}}</span>

  <div class="login_register_block">
    <span class="common_small_span">
      <span class="common_small_span_grey">{{localize.language.byRegistration}} </span>
      <a class="common_link" target="_blank" href="assets/pdfs/INVESTMENT_SERVICES_AGREEMENT.pdf">{{localize.language.userAgreement}}</a>
    </span>
    <button routerLink="/register" class="common_button dark_button">{{localize.language.register}}</button>
    <button routerLink="/login" class="common_button transparent_button">{{localize.language.loginCabinet}}</button>
  </div>
</div>
