<div class="details_section">

  <div class="details_header_section">
    <div style="overflow: hidden">
      <h1 data-aos="fade-up" data-aos-duration="500">{{localize.language.company}}</h1>
    </div>
  </div>

  <div style="width: 1200px; display:flex; justify-content: space-between">
    <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="200" class="under_line_awards_left"></div>
    <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="200" class="under_line_awards_right"></div>
  </div>

  <div class="golden_standard_section">

    <div class="golden_standard_block">
      <div class="golden_standard_info" data-aos="fade-in" data-aos-duration="500" data-aos-delay="500">
        <span class="common_medium_span">{{localize.language.goldenStandard}}</span>
        <h2>{{localize.language.goldenStandardText}}</h2>
      </div>
      <div style="overflow: hidden">
        <img data-aos-delay="600" data-aos-duration="500" data-aos="zoom-out-left" src="../../../../../assets/images/shared/company/company.svg">
      </div>
    </div>
  </div>

  <div class="company_heads_section">
    <div class="company_heads_outer_block">
      <div style="overflow: hidden">
        <img [src]="screenWidth > 1580 ? 'assets/images/shared/company/companyHead.jpg' : 'assets/images/shared/company/companyHeadSmall.svg'"/>
      </div>

      <div class="company_heads_info">
        <img width="55px" src="assets/images/shared/ui-kit/quoteMark.svg">
        <h1>{{localize.language.companyHeadText}}</h1>
        <span class="common_big_span">{{localize.language.companyHeadName}}</span>
        <span class="common_big_span_grey">{{localize.language.companyHeadOccupation}}</span>
      </div>
    </div>
  </div>

  <div class="our_history_section">
    <div class="our_history_section_d">
      <div class="common_medium_span" style="overflow: hidden">
        <div data-aos="fade-in" data-aos-duration="500" data-aos-delay="800">
          <span>{{localize.language.ourHistory}}</span>
        </div>
      </div>
      <div style="display:flex; justify-content: space-between">
        <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="800" class="under_line_awards_left"></div>
        <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="800" class="under_line_awards_right"></div>
      </div>
    </div>

    <div class="our_history_body_section">
      <div class="our_history_left">
        <div style="overflow: hidden" class="our_history_left_2014">
          <h1 data-aos="fade-up" data-aos-duration="500" data-aos-delay="500" data-aos-offset="500">2014 {{localize.language.year}}</h1>
        </div>
        <div style="overflow: hidden" class="our_history_left_2017">
          <h1 data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000" data-aos-offset="500">2017 {{localize.language.year}}</h1>
        </div>
        <div style="overflow: hidden" class="our_history_left_2019">
          <h1 data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000" data-aos-offset="500">2019 {{localize.language.year}}</h1>
        </div>
        <div style="overflow: hidden" class="our_history_left_2020">
          <h1 data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000" data-aos-offset="500">2020 {{localize.language.year}}</h1>
        </div>
        <div style="overflow: hidden" class="our_history_left_2021">
          <h1 data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000" data-aos-offset="500">2021 {{localize.language.year}}</h1>
        </div>
        <div style="overflow: hidden">
          <h1 data-aos="fade-up" data-aos-duration="500" data-aos-delay="1300" data-aos-offset="700">2022 {{localize.language.year}}</h1>
        </div>
      </div>

      <div class="our_history_middle">
        <div class="circle_history"></div>
        <div class="line_history line_1"></div>

        <div class="circle_history"></div>
        <div class="line_history line_2"></div>

        <div class="circle_history"></div>
        <div class="line_history line_3"></div>

        <div class="circle_history"></div>
        <div class="line_history line_4"></div>

        <div class="circle_history"></div>
        <div class="line_history line_5"></div>

        <div class="circle_history_last"></div>
      </div>

      <div class="our_history_right">
        <div data-aos="fade-in" data-aos-duration="500" data-aos-delay="800" class="our_history_right_1">
          <span class="common_big_span_grey">{{localize.language.ourHistoryText1}}</span>
        </div>

        <div class="our_history_right_2">
          <div style="overflow: hidden; min-width: 370px">
            <img data-aos="zoom-out-left" data-aos-delay="1300" data-aos-duration="500" width="370px" src="assets/images/shared/company/companyHistory2017.svg">
          </div>
          <span data-aos="fade-in" data-aos-duration="500" data-aos-delay="1600" data-aos-offset="500" class="common_big_span_grey">{{localize.language.ourHistoryText2}}</span>
        </div>

        <div class="our_history_right_2">
          <span data-aos="fade-in" data-aos-duration="500" data-aos-delay="1300" data-aos-offset="800" class="common_big_span_grey">{{localize.language.ourHistoryText3}}</span>
          <div style="overflow: hidden; min-width: fit-content">
            <img data-aos="zoom-out-left" data-aos-delay="1600" data-aos-duration="500"  src="assets/images/shared/company/companyHistory2019.svg">
          </div>
        </div>

        <div class="our_history_right_2">
          <div style="overflow: hidden; min-width: fit-content">
            <img data-aos="zoom-out-left" data-aos-delay="2600" data-aos-duration="500" data-aos-offset="800" src="assets/images/shared/company/companyHistory2020.svg">
          </div>
          <span data-aos="fade-in" data-aos-duration="500" data-aos-delay="2000" data-aos-offset="800" class="common_big_span_grey">{{localize.language.ourHistoryText4}}</span>
        </div>

        <div data-aos="fade-in" data-aos-duration="500" data-aos-delay="1500" data-aos-offset="1500" class="our_history_right_3">
          <span class="common_big_span_grey">{{localize.language.ourHistoryText5}}</span>
        </div>

        <div data-aos="fade-in" data-aos-duration="500" data-aos-delay="1900" data-aos-offset="1500">
          <span class="common_big_span_grey">{{localize.language.outHistoryText6}}</span>
        </div>
      </div>
    </div>
  </div>

  <app-footer-links></app-footer-links>
</div>
