<div class="sidebar_whole_section">
  <div class="sidebar_outer" (click)="headerService.closeSideBar()"></div>
  <div class="sidebar_section">
    <div>
      <div class="mobile_header_icons">
        <div class="language_section" id="language_section">
          <div class="common_dropdown_cycle_mobile" (click)="headerService.openLanguageDrpDwn()">{{localize.language[sharedService.currentLanguage]}}</div>
          <div class="common_dropdown_mobile common_dropdown_mobile_l" *ngIf="headerService.languageStatus">
            <span (click)="headerService.changeLanguage(localize.firstLanguage)">{{localize.language[localize.firstLanguage]}}</span>
            <span (click)="headerService.changeLanguage(localize.secondLanguage)">{{localize.language[localize.secondLanguage]}}</span>
          </div>
        </div>

        <div class="user_block" id="user_section">
          <div *ngIf="!headerService.userName else logOut" class="common_dropdown_cycle_mobile" (click)="headerService.userIcon()">
            <img src="assets/images/mobile/general/userIconLightMobile.svg" alt="user">
          </div>
          <ng-template #logOut>
            <div class="common_dropdown_cycle_mobile" (click)="headerService.openLogOut()">
              <img src="assets/images/mobile/general/userIconLightMobile.svg" alt="user">
            </div>
          </ng-template>
          <div *ngIf="headerService.logOutStatus" class="common_dropdown_mobile">
            <div (click)="registerService.logOutUser()" class="user_log_out_mobile"></div>
          </div>
        </div>

        <img (click)="headerService.closeSideBar()"
             src="assets/images/shared/ui-kit/closeIconDarkGeneral.svg" alt="logo">
      </div>
    </div>
    <ul style="margin-top: 30px">
      <li routerLink="/company" (click)="headerService.closeSideBar()" class="sidebar_menu_item">{{localize.language.company}}</li>
      <li routerLink="/awards" (click)="headerService.closeSideBar()" class="sidebar_menu_item">{{localize.language.awards}}</li>
      <li routerLink="/services" (click)="headerService.closeSideBar()" class="sidebar_menu_item">{{localize.language.services}}</li>
      <li routerLink="/ideas" (click)="headerService.closeSideBar()" class="sidebar_menu_item">{{localize.language.ideas}}</li>
      <li routerLink="/contact" (click)="headerService.closeSideBar()" class="sidebar_menu_item">{{localize.language.contact}}</li>
      <li routerLink="/account/account-overview" (click)="headerService.closeSideBar()" class="sidebar_menu_item">{{localize.language.personalCabinet}}
      </li>
    </ul>
  </div>
</div>

