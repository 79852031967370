<div class="map_section_contact">

  <div class="contact_info_section">
    <div class="contact_info_block" *ngFor="let contact of companyService.currentCountryContacts">
      <h2>{{contact.city}}</h2>
      <div class="contact_info">
        <span class="common_big_span_grey">{{contact.phone ? localize.language.phone + ': ' + contact.phone : ''}}</span>
        <span class="common_big_span_grey">{{contact.email}}</span>
      </div>
    </div>
  </div>
</div>
